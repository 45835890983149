<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="elementData === undefined"
    >
      <h4 class="alert-heading">
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.fetching_single_data') }}
      </h4>
      <div class="alert-body">
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.check') }}
        <b-link
          class="alert-link"
          :to="{ name: 'medicine-delivery-list'}"
        >
          {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.list') }}
        </b-link>
        {{ $t(RESOURCES_ELEMENT_NAME + '.search.error.to_find_other') }}
      </div>
    </b-alert>

    <template v-if="elementData && serviceBundleList">
      <element-edit-tab
        :element-data="elementData"
        :service-bundle-list="serviceBundleList"
        :medicine-availability="medicineAvailability"
        class="mt-2 pt-75"
      />
    </template>

  </div>
</template>

<script>
import {
  BAlert, BLink,
} from 'bootstrap-vue'
import router from '@/router'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import elementStoreModule from '../storeModule'
import ElementEditTab from './EditCard.vue'

export default {
  components: {
    BAlert,
    BLink,
    ElementEditTab,
  },
  data() {
    return {
      ARL_CODES_APP_STORE_MODULE_NAME: 'app-arl-codes',
      RESOURCES_ELEMENT_NAME: 'medicine_delivery',
      newElement: true,
      serviceBundleList: [],
      medicineAvailability: [],
      elementData: undefined,
    }
  },
  async mounted() {
    // Register module
    if (!store.hasModule(this.RESOURCES_ELEMENT_NAME)) store.registerModule(this.RESOURCES_ELEMENT_NAME, elementStoreModule)

    this.medicineAvailability = await this.fetchMedicineAvailability()
    this.elementData = await this.fetchElement()
    this.serviceBundleList = await this.fetch_service_bundles()
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.RESOURCES_ELEMENT_NAME)) store.unregisterModule(this.RESOURCES_ELEMENT_NAME)
  },
  methods: {
    process_availability(elementData) {
      for (const item of elementData.services) {
        const medicine = this.medicineAvailability.find(element => element.cups == item.service.cups)
        let quant = 0
        if (medicine) {
          quant = medicine.stock_quantity
        }
        item.available_quant = quant - item.dosage
      }
      elementData.services_processed = true
    },
    async fetch_service_bundles() {
      return store.dispatch(`${this.RESOURCES_ELEMENT_NAME}/fetch_service_bundles`, { arl_id: this.elementData.arl_id }).then(response => {
        const list = response.data
        list.unshift({ id: null, bundle_name_value: this.$t('list.defaultOption') })
        return list
      }).catch(error => {
        if (error.response.status === 404) {
          return undefined
        }
      })
    },
    async fetchMedicineAvailability() {
      return store.dispatch(`${this.RESOURCES_ELEMENT_NAME}/fetch_medicine_availability`, {
        perPage: 10000,
        page: 1,
        query: '',
      })
        .then(response => response.data.items)
        .catch(error => undefined)
    },
    async fetchElement() {
      this.newElement = false
      return store.dispatch(`${this.RESOURCES_ELEMENT_NAME}/fetch_element`, { id: router.currentRoute.params.id })
        .then(response => {
          const { data } = response
          data.services = []
          for (const bundle of data.bundles) {
            for (const service of bundle.services) {
              data.services.push(service)
            }
          }
          this.process_availability(data)
          return data
        })
        .catch(error => undefined)
    },
    save(elementData) {
      const method = `${this.RESOURCES_ELEMENT_NAME}/save_element`
      this.newElement = false
      const _self = this
      let error = false
      for (const element of this.elementData.services) {
        if (element.available_quant < 0) {
          error = true
          break
        }
      }
      if (error) {
        _self.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: _self.$t(`${_self.RESOURCES_ELEMENT_NAME}.action_results.medicine_delivery.title`),
            icon: 'SaveIcon',
            variant: 'danger',
            text: _self.$t(`${_self.RESOURCES_ELEMENT_NAME}.action_results.medicine_delivery.failure_stock`),
          },
        })
      } else {
        store
          .dispatch(method, { data: elementData })
          .then(response => {
            _self.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: _self.$t(`${_self.RESOURCES_ELEMENT_NAME}.action_results.medicine_delivery.title`),
                icon: 'SaveIcon',
                variant: 'success',
                text: _self.$t(`${_self.RESOURCES_ELEMENT_NAME}.action_results.medicine_delivery.success`),
              },
            })
            _self.$router.replace({ name: 'medicine-delivery-list' })
          })
          .catch(error => {
            _self.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: _self.$t(`${_self.RESOURCES_ELEMENT_NAME}.action_results.medicine_delivery.title`),
                icon: 'SaveIcon',
                variant: 'danger',
                text: _self.$t(`${_self.RESOURCES_ELEMENT_NAME}.action_results.medicine_delivery.failure`),
              },
            })
          })
      }
    },
  },
}
</script>

<style>

</style>
