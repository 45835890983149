var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{directives:[{name:"id",rawName:"v-id",value:(_vm.elementData && _vm.elementData.services_processed),expression:"elementData && elementData.services_processed"}],on:{"submit":function($event){$event.preventDefault();return _vm.save($event)}}},[_c('validation-observer',{ref:"elementForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-card',[_c('b-card-header',[_c('div',{staticClass:"text-primary h2"},[_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"StarIcon","size":"25","variant":"primary"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Información Principal")])],1)]),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label-for":"number"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.number')))]),_c('b-form-input',{attrs:{"id":"number","disabled":true},model:{value:(_vm.medical_order),callback:function ($$v) {_vm.medical_order=$$v},expression:"medical_order"}})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label-for":"date"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.generation_date')))]),_c('b-form-input',{attrs:{"id":"date","disabled":true},model:{value:(_vm.dateParsed),callback:function ($$v) {_vm.dateParsed=$$v},expression:"dateParsed"}})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label-for":"arl_name"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.arl')))]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.arl')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"arl_name","disabled":true},model:{value:(_vm.elementData.arl_name),callback:function ($$v) {_vm.$set(_vm.elementData, "arl_name", $$v)},expression:"elementData.arl_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label-for":"medical_order_status"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.arl_code_order_status')))]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.medical_order_status')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"medical_order_status","disabled":true},model:{value:(_vm.elementData.medical_order_status.value),callback:function ($$v) {_vm.$set(_vm.elementData.medical_order_status, "value", $$v)},expression:"elementData.medical_order_status.value"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label-for":"case_number"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.case_number')))]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.case_number')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"case_number","disabled":true},model:{value:(_vm.elementData.case_number),callback:function ($$v) {_vm.$set(_vm.elementData, "case_number", $$v)},expression:"elementData.case_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"2"}},[_c('b-form-group',{attrs:{"label-for":"internal_code"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.internal_code')))]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.internal_code')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"internal_code","disabled":true},model:{value:(_vm.elementData.internal_code),callback:function ($$v) {_vm.$set(_vm.elementData, "internal_code", $$v)},expression:"elementData.internal_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label-for":"patient_identity"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.patient_identity')))]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.patient_identity')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"patient_identity","disabled":true},model:{value:(_vm.elementData.patient_identity),callback:function ($$v) {_vm.$set(_vm.elementData, "patient_identity", $$v)},expression:"elementData.patient_identity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label-for":"patient_name"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.patient_name')))]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.patient_name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"patient_name","disabled":true},model:{value:(_vm.elementData.patient_name),callback:function ($$v) {_vm.$set(_vm.elementData, "patient_name", $$v)},expression:"elementData.patient_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label-for":"service_type"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.service_type')))]),_c('b-form-input',{attrs:{"id":"service_type","disabled":true},model:{value:(_vm.elementData.service_type.value),callback:function ($$v) {_vm.$set(_vm.elementData.service_type, "value", $$v)},expression:"elementData.service_type.value"}})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label-for":"follow_type_name"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.follow_type')))]),_c('validation-provider',{attrs:{"name":_vm.$t(_vm.resourcesElementName + '.object.follow_type')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"follow_type_name","disabled":true},model:{value:(_vm.elementData.follow_type_name),callback:function ($$v) {_vm.$set(_vm.elementData, "follow_type_name", $$v)},expression:"elementData.follow_type_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),(_vm.elementData.next_consult)?_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label-for":"next_consult"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t(_vm.resourcesElementName + '.object.next_consult')))]),_c('b-form-input',{attrs:{"id":"next_consult","disabled":true},model:{value:(_vm.elementData.next_consult.value),callback:function ($$v) {_vm.$set(_vm.elementData.next_consult, "value", $$v)},expression:"elementData.next_consult.value"}})],1)],1):_vm._e()],1),_c('b-row',[_c('groups-tab',{attrs:{"element-data":_vm.elementData,"service-bundle-list":_vm.serviceBundleList,"medicine-availability":_vm.medicineAvailability}})],1)],1)],1),_c('b-row',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",on:{"click":function($event){_vm.router().go(-1)}}},[_vm._v(" "+_vm._s(_vm.$t('actions.back'))+" ")]),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","type":"submit","disabled":invalid || _vm.not_stock}},[_vm._v(" "+_vm._s(_vm.$t('actions.deliver'))+" ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }