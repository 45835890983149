import handlerDataSearch from '@/handlers/handlerDataSearch'
import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetch_medicine_availability(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/service/medicines`, { params: params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_elements(ctx, params) {
      const currentParams =  handlerDataSearch(params)
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/medical_order/medicine_delivery`, { params: currentParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_excel_elements(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/medical_order/medicine_delivery`, { params: params.queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_element(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/medical_order/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    save_element(ctx, elementData) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/v1/cases/medical_order/medicine_delivery/${elementData.data.id}`, elementData.data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchArls(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/cases/company', { 
            params: { perPage: 500, page: 1, companyType: 'ARL' } 
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_service_bundles(ctx, { arl_id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/v1/cases/service_bundle/by_arl/${arl_id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetch_key_value(ctx, { type }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/type_value/' + type, { 
            params: { deep: 1 } 
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCountries(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/type_value/geography', { 
            params: { deep: 1 } 
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchDepartments(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/type_value/geography', { 
            params: { deep: 2 } 
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCities(ctx, {parent}) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/v1/core/type_value/geography', { 
            params: { deep: 3, parent: parent } 
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
