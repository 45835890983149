<template>
  <div>
    <b-card-header>
      <div class="text-primary h2">
        <span class="d-none d-sm-inline">{{
          $t(resourcesElementName + '.plural_name')
        }}</span>
      </div>
    </b-card-header>
    <b-card-body class="p-0">
      <b-table
        v-if="elementData.services"
        id="refElementsListTable"
        ref="refElementsListTable"
        :empty-text="$t(resourcesElementName + '.search.not_found')"
        :fields="tableColumns"
        :items="elementData.services"
        class="position-relative"
        primary-key="id"
        responsive
        show-empty
        :tbody-tr-class="rowClass">
        <template #cell(authorization_date)="data">
          {{ formatDate(data.item, 'authorization_date') }}
        </template>
        <template #cell(vigence_date)="data">
          {{ formatDate(data.item, 'vigence_date') }}
        </template>
        <template #cell(services)="data">
          {{ getServices(data.item) }}
          <b-button
            :id="`see-services-${data.item.id}`"
            style="margin: 1px"
            variant="primary"
            @click="seeServices(data.item)">
            <feather-icon icon="EyeIcon" />
          </b-button>
          <!-- ? SEE -->
          <b-tooltip
            :target="`see-services-${data.item.id}`"
            :title="$t('actions.details')"
            triggers="hover"
            :noninteractive="true"
            placement="topleft">
          </b-tooltip>
        </template>
        <!-- Column: actions -->
        <template #cell(actions)="data">
          <b-col class="flex flex-row flex-wrap">
            <b-button
              :id="`edit-button-${data.item.id}`"
              style="margin: 1px"
              variant="primary"
              @click="editService(data.item)">
              <feather-icon icon="EditIcon" />
            </b-button>
            <!-- ? EDIT -->
            <b-tooltip
              :target="`edit-button-${data.item.id}`"
              :title="$t('actions.edit')"
              triggers="hover"
              :noninteractive="true"
              placement="topleft">
            </b-tooltip>
          </b-col>
        </template>
      </b-table>
    </b-card-body>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BModal,
  BRow,
  BTable,
  BTooltip,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import moment from 'moment'

export default {
  components: {
    BTooltip,
    BDropdown,
    BDropdownItem,
    BModal,
    BTable,
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BFormInput,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BForm,
    BFormSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    elementData: {
      type: Object,
      required: true,
    },
    serviceBundleList: {
      type: Array,
      required: true,
    },
    medicineAvailability: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      tableColumns: [],
      resourcesElementName: '',
      required,
      isNew: false,
      currentGroupData: {},
    }
  },
  computed: {},
  beforeDestroy() {},
  mounted() {
    this.resourcesElementName = 'services'
    this.tableColumns = [
      {
        key: 'service.cups',
        label: this.$t(`${this.resourcesElementName}.object.cum_file`),
        sortable: true,
      },
      {
        key: 'service.cups_name',
        label: this.$t(`${this.resourcesElementName}.object.generic_name`),
        sortable: true,
      },
      {
        key: 'concentration',
        label: this.$t(`${this.resourcesElementName}.object.concentration`),
        sortable: false,
        formatter: (value, key, item) =>
          `${item.service.quantity} ${item.service.units_of_measure.value}`,
      },
      {
        key: 'service.dosage_form.value',
        label: this.$t(`${this.resourcesElementName}.object.dosage_form`),
        sortable: false,
      },
      {
        key: 'service.administration_route.value',
        label: this.$t(
          `${this.resourcesElementName}.object.administration_route`
        ),
        sortable: false,
      },
      {
        key: 'dosage',
        label: this.$t(`${this.resourcesElementName}.object.quantity`),
        sortable: false,
      },
      {
        key: 'frequency',
        label: this.$t(`${this.resourcesElementName}.object.dosage`),
        sortable: false,
      },
      {
        key: 'service.treatment_time',
        label: this.$t(`${this.resourcesElementName}.object.treatment_time`),
        sortable: false,
      },
    ]

    const dataObject = JSON.parse(this.elementData.data)

    for (let i = 0; i < this.elementData.services.length; i++) {
      if (dataObject?.services[i]?.treatment_time) {
        this.elementData.services[i].service.treatment_time =
          dataObject.services[i].treatment_time
      }
    }
  },
  methods: {
    seeServices(group) {
      this.currentGroupData = group
      this.$root.$emit('bv::show::modal', 'modal-service-detail')
    },
    getServices(element) {
      let ret = ''
      for (const service of element.services) {
        console.log(service.service)
        ret += `${service.service.cups || service.service.cum_file} `
      }
      return ret
    },
    editService(element) {
      this.currentGroupData = element
      this.isNew = false
      this.$root.$emit('bv::show::modal', 'modal-service-edit')
    },
    formatDate(element, field) {
      return element[field]
        ? moment(element[field], 'DD/MM/YYYY').format('DD/MM/YYYY')
        : ''
    },
    rowClass(item, type) {
      console.log(item.available_quant)
      if (item.available_quant < 0) {
        return 'bg-danger'
      }
    },
  },
}
</script>

<style></style>
